@import '../../styles/constants';

.container {
    max-width: 1000px;
    margin: 30px auto;
    padding-bottom: 30px;

    .header {

    }
}
