@import '../../../../styles/constants';

.span {
    all: unset;
    color: $WHITE;
    border-bottom: 1px solid white;
    padding: 10px;
    margin: 5px 0;
    border-radius: 0px;
    outline: none;
}

