@import '../../styles/constants';

button {
    all: unset;
    cursor: pointer;

}

.button {
    padding: 10px 20px;
    border: 1px solid $WHITE;
    border-radius: $BORDER_RADIUS;
    box-shadow: $BOX_SHADOW;
    transition:  $TRANSITION;
    color: $WHITE;
    margin: 5px;
    text-align: center;
    min-width: 30px;
}

.primary:hover {
    border: 1px solid $PRIMARY;
    background-color: rgba($PRIMARY,0.1);
}

.secondary:hover {
    border: 1px solid $SECONDARY;
    background-color: rgba($SECONDARY,0.1);
}