@import '../../styles/constants';


.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: $PADDING 0;
    
}

.formField {
    display: flex;
    flex-direction: column;

    label {
        color: $GREY 
    }
    margin: 10px;
}

.twoColumns {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    
}