@import '../../styles/constants';

nav {
    background-color: rgba($WHITE, 0.2);
    // border-bottom: 1px solid white;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        all: unset;
        cursor: pointer;
        color: $WHITE;
        transition: $TRANSITION;
    }

    a:hover {
        // text-decoration: underline;
        color: $PRIMARY;

    }
}