// Colours 
// $PRIMARY: #23CE6B;
$PRIMARY: #2EBFA5;
$SECONDARY: #8390FA;
// $SECONDARY: #333333;
$NAV: #cccccc;
$BLACK: #000000;
$WHITE: #ffffff;
$GREY: #5c5c5c;
$RED: red;
$BOX_SHADOW: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
// $BOX_SHADOW: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;


// Dimensions
$PADDING: 20px;
$BORDER_RADIUS: 4px;


// Animations
$TRANSITION: all 0.2s ease-in-out;