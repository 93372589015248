@import '../../styles/constants';

.tabs {

    height: 40px;
    border-bottom: 1px solid $GREY;

    .tab {
        color: $WHITE;
        width: 100%;
        text-align: center;
        // border: 1px solid $GREY;
    }
    .active {
        background-color: rgba($SECONDARY, 0.1);
        // border: 1px solid $SECONDARY;
        border-bottom: 1px solid $SECONDARY;
    }
}