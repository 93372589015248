@import '../../styles/constants';

.table {
    border-radius: $BORDER_RADIUS;
    margin: $PADDING 0;

    .rdt_TableRow:hover {

        background-color: rgba($SECONDARY, 0.3);
        cursor: pointer;
    }
}