.header-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .button {
        width: 10px;

    }
}