@import '../../../../styles/constants';

.dashBox {
    padding: $PADDING;
    margin: $PADDING;;
    
    color: $WHITE;
    background-color: rgba($BLACK, 0.5);

    border-radius: $BORDER_RADIUS;
    box-shadow: $BOX_SHADOW;

    .value {
        font-size: 50px;
        font-family: 'Mr Dafoe', serif;
        background: -webkit-linear-gradient(#2EBFA5, #8390FA);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .description {

    }
}