@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');

* {
  margin: 0;
  padding: 0;
}
  
body {
  font-family: 'Helvetica', sans-serif;
  background-color: black;


  h1, h2, h3, h4, h5, h6 {
    font-family: 'Mr Dafoe', serif;
    color: white;
    margin: 10px 0;
    padding: 0 10px;
    font-weight: 100;
    opacity: 0.4;
  }

  h1 {
    font-size: 100px;
    opacity: 1;
    background: -webkit-linear-gradient(#2EBFA5, #8390FA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-size: 80px;
  }


  h3 {
    font-size: 50px;
  }

  main {
    min-height: 100vh;
  }
  

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .row-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

}
