.auth {
    background: url("../../../public/images/background.png") ;
    background-size: cover;
    background-position: center;

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    h1 {
        font-size: 120px;
        position: absolute;
        top: 100px;
        left: 60px;
      }
}