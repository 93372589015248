@import '../../../../styles/constants';

.input {
    all: unset;
    color: $WHITE;
    border-bottom: 1px solid white;
    padding: 10px;
    margin: 5px 0;
    border-radius: 0px;
    outline: none;
}

.input:focus {
    border-bottom: 1px solid $PRIMARY;
}

input[type="checkbox"] {
    appearance: none;
    background-color: $WHITE;
    margin-top: 20px;
    font: inherit;
    color: currentColor;
    width: 20px;
    height:20px;
    padding: 0px;
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
    align-self: center;

    &::before {
        content: "";
        width: 15px;
        height: 15px;
        transform: scale(0);
        transition: $TRANSITION;
    }

    &:checked::before {
        background-color: $PRIMARY;
        transform: scale(1);
      }
}