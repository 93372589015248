@import '../../../../../../styles/constants';

.expandable {
    
    .row {
        height: 50px;
        padding-left: 70px;
        padding-right: 20px;
        display: grid;
        grid-template-columns: 4fr 1fr;
        align-items: center;
        background-color: rgba($SECONDARY, 0.3);
        border-bottom: 1px solid rgba($SECONDARY, 0.3);

        .col {
            display: flex;
            align-items: center;
            
            span {
                font-size: 13px;
            }
        }
    }

}