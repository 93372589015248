@import '../../styles/constants';

.modal {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.9); 
    overflow-x: hidden;
    transition: $TRANSITION;
    display: flex;
    align-items: center;
    justify-content: center;


    .modalContainer {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $WHITE;
        border-radius: $BORDER_RADIUS;
        padding: $PADDING;

        span {
            color: $WHITE;
            padding-bottom: 10px;
        }
    }
}